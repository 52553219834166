import React, { useState } from "react";
import * as styles from "./LicenseCards.module.scss";
import { businessData, data } from "./data";
import LicenseCard from "../../molecules/LicenseCard/LicenseCard";
export default function LicenseCards() {
  const [tabs, setTabs] = useState("Free");
  const [toggle, setToggle] = useState("Regular");
  return (
    <div className={styles.licenseCards}>
      <h1>Licenses</h1>
      <div className={styles.tabs}>
        <button
          onClick={() => setTabs("Free")}
          className={tabs === "Free" ? styles.active : ""}
        >
          2 Weeks Free
        </button>
        <button
          onClick={() => setTabs("Individuals")}
          className={tabs === "Individuals" ? styles.active : ""}
        >
          Individuals
        </button>
        <button
          onClick={() => setTabs("Business")}
          className={tabs === "Business" ? styles.active : ""}
        >
          Business
        </button>
      </div>
      {tabs !== "Free" && (
        <div className={styles.toggle}>
          <p className={`${toggle === "Regular" ? styles.active : ``}`}>
            Regular
          </p>
          <div
            onClick={() => setToggle(toggle === "Lite" ? "Regular" : "Lite")}
            className={`${styles.toggleSwitch} ${
              toggle === "Lite" ? styles.toggled : ``
            }`}
          ></div>
          <p className={`${toggle === "Lite" ? styles.active : ``}`}>Lite</p>
        </div>
      )}
      <div
        className={`${styles.flexItems} ${
          (tabs === "Business" && styles.businessData,
          tabs === "Free" && styles.freeData)
        }`}
      >
        {tabs === "Individuals" ? (
          data.map((license, i) => (
            <LicenseCard toggle={toggle} key={i} {...license} />
          ))
        ) : tabs === "Free" ? (
          <div className={styles.freeSection}>
            <img src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1725036375/Templates/Group_1321314422_cmykcd.png" />
            <div className={styles.text}>
              <h1 className={styles.title}>Get a Hands-on Experience</h1>
              <ul>
                <li>Improved video meeting experience</li>
                <li>Better presentations in Studio & Library </li>
                <li>Smarter info. sharing</li>
                <li>Master organization</li>
              </ul>
              <button
                onClick={() =>
                  window.open(
                    "https://accounts.masterwizr.com/sign-up",
                    "_blank"
                  )
                }
              >
                Keep 2 weeks free
              </button>
            </div>
          </div>
        ) : (
          businessData.map((license, i) => (
            <LicenseCard key={i} toggle={toggle} {...license} />
          ))
        )}
      </div>
      {tabs !== "Free" && (
        <p className={styles.individualText}>
          Beyond free trial, choose Lite for +60% of features for less pay, or
          regular for the full experience.
        </p>
      )}
    </div>
  );
}
