// extracted by mini-css-extract-plugin
export var active = "LicenseCards-module--active--36489";
export var businessData = "LicenseCards-module--businessData--cb585";
export var desc = "LicenseCards-module--desc--832cb";
export var flexItems = "LicenseCards-module--flexItems--3d257";
export var freeData = "LicenseCards-module--freeData--f2d8d";
export var freeSection = "LicenseCards-module--freeSection--f12af";
export var individualText = "LicenseCards-module--individualText--e8287";
export var licenseCards = "LicenseCards-module--licenseCards--d585a";
export var tabs = "LicenseCards-module--tabs--e3c48";
export var text = "LicenseCards-module--text--94f82";
export var title = "LicenseCards-module--title--64146";
export var toggle = "LicenseCards-module--toggle--8ddb9";
export var toggleSwitch = "LicenseCards-module--toggleSwitch--7392d";
export var toggled = "LicenseCards-module--toggled--f2607";